import { initializeApp } from "firebase/app";
import { child, get, getDatabase, onValue, ref } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyBE0FMGLOKyJjp3go1PcSZtphLnXbylWO8",
  authDomain: "daily-trader.firebaseapp.com",
  databaseURL: "https://daily-trader-default-rtdb.firebaseio.com",
  projectId: "daily-trader",
  storageBucket: "daily-trader.appspot.com",
  messagingSenderId: "81171688680",
  appId: "1:81171688680:web:55758b94efa9c77177ed5d",
};

const app = initializeApp(firebaseConfig);
const dbRef = ref(getDatabase(app));

export const getAllData = async () => {
  let data;
  try {
    const snapshot = await get(child(dbRef, `apps/`));
    if (snapshot.exists()) {
      data = snapshot.val();
      console.log(snapshot.val());
    } else {
      console.log("No data available");
    }
  } catch (error) {
    console.error(error);
  }
  return data;
};

export const subscribeToDataChanges = (callback: (data: any) => void) => {
  try {
    getAllData().then(callback);
    const dataRef = child(dbRef, `apps/`);

    onValue(dataRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        console.log(data);
        callback(data);
      } else {
        console.log("No data available");
      }
    });
  } catch (error) {
    console.error(error);
  }
};
