import { useCallback, useEffect, useState } from "react";
import Dropdown from "react-dropdown";
import styles from "./App.module.scss";
import Stat from "./components/Stat/Stat";
import { getAllData, subscribeToDataChanges } from "./firebase";

type DataFormat = {
  [id: string]: SingleDatapoint;
};
type SingleDatapoint = {
  Sensors: any[];
  Status: any[];
  name: string;
  timestamp: number;
};
enum SensorTypes {
  UVA = "UV A",
  UVB = "UV B",
  UVC = "UV C",
  Temperature = "Temp",
  Pressure = "Pres",
  Rh = "Rh",
  Soil = "Soil",
}

enum StatusTypes {
  Fan = "Fan",
  Lights = "Lights",
  PDLC = "PDLC",
}

const LAST_X = [
  "7 Days",
  "2 Days",
  "24 Hours",
  "12 Hours",
  "Hour",
  "10 minutes",
];

const LAST_X_MAP = {
  "24 Hours": 1,
  "7 Days": 7,
  "2 Days": 2,
  "12 Hours": 0.5,
  Hour: 1 / 24,
  "10 minutes": (1 / 24) * (1 / 6),
} as any;

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [firebaseData, setFirebaseData] = useState<DataFormat>({});
  const [timeFrame, setTimeFrame] = useState<string>(LAST_X[0]);

  useEffect(() => {
    let prevTime = new Date().getTime();
    let initial = true;
    subscribeToDataChanges((data) => {
      let currTime = new Date().getTime();
      if (currTime - prevTime >= 2500 || initial) {
        setFirebaseData(data as DataFormat);
        setIsLoading(false);
        initial = false;
        prevTime = currTime;
      }
    });
  }, []);

  const aggregate_sensor = useCallback(
    (data: DataFormat, sensor_type: SensorTypes) => {
      let out_data: any[] = [];
      const currentTime = new Date().getTime();

      for (const [_, datapoint] of Object.entries(data)) {
        const datapointTime = new Date(datapoint.timestamp * 1000);
        if (
          currentTime - datapointTime.getTime() <=
          LAST_X_MAP[timeFrame] * 24 * 60 * 60 * 1000
        ) {
          out_data.push({
            [sensor_type]: datapoint.Sensors.find(
              (value) => value[sensor_type] !== undefined,
            )?.[sensor_type],
            name: `${datapointTime.getHours()}:${
              (datapointTime.getMinutes() < 10 ? "0" : "") +
              datapointTime.getMinutes()
            }`,
          });
        }
      }

      return out_data;
    },
    [timeFrame],
  );

  const aggregate_statuses = useCallback(
    (data: DataFormat, status_type: StatusTypes) => {
      let out_data: any[] = [];
      const currentTime = new Date().getTime();

      for (const [_, datapoint] of Object.entries(data)) {
        const datapointTime = new Date(datapoint.timestamp * 1000);
        if (
          currentTime - datapointTime.getTime() <=
          LAST_X_MAP[timeFrame] * 24 * 60 * 60 * 1000
        ) {
          out_data.push({
            [status_type]: datapoint.Status.find(
              (value) => value[status_type] !== undefined,
            )?.[status_type]
              ? 1
              : 0,
            name: `${datapointTime.getHours()}:${
              (datapointTime.getMinutes() < 10 ? "0" : "") +
              datapointTime.getMinutes()
            }`,
          });
        }
      }

      return out_data;
    },
    [timeFrame],
  );

  if (isLoading) {
    return <div className={styles.loadingContainer}>Loading...</div>;
  }

  return (
    <div className={styles.app}>
      <header className={styles.appHeader}>
        <div>WELCOME TO THE GREENHOUSE</div>
        <Dropdown
          className={styles.timeFrameDropdown}
          options={LAST_X}
          menuClassName={styles.timeFrameDropdownMenu}
          onChange={(option) => {
            setTimeFrame(option.value);
            getAllData().then((data) => {
              setFirebaseData(data);
            });
          }}
          value={timeFrame}
        />
      </header>
      <div className={styles.contentBody}>
        <Stat
          title="UV A"
          data={aggregate_sensor(firebaseData, SensorTypes.UVA)}
          type={SensorTypes.UVA}
          unit="uW/cm^2"
        />
        <Stat
          title="UV B"
          data={aggregate_sensor(firebaseData, SensorTypes.UVB)}
          type={SensorTypes.UVB}
          unit="uW/cm^2"
        />
        <Stat
          title="UV C"
          data={aggregate_sensor(firebaseData, SensorTypes.UVC)}
          type={SensorTypes.UVC}
          unit="uW/cm^2"
        />
        <Stat
          title="Temperature"
          data={aggregate_sensor(firebaseData, SensorTypes.Temperature)}
          type={SensorTypes.Temperature}
          unit="C°"
        />
        <Stat
          title="Pressure"
          data={aggregate_sensor(firebaseData, SensorTypes.Pressure)}
          type={SensorTypes.Pressure}
          unit="hPa"
        />
        <Stat
          title="Soil"
          data={aggregate_sensor(firebaseData, SensorTypes.Soil)}
          type={SensorTypes.Soil}
          unit="%"
        />
        <Stat
          title="Relative Humidity"
          data={aggregate_sensor(firebaseData, SensorTypes.Rh)}
          type={SensorTypes.Rh}
          unit="Rh"
        />
        {/* Statuses */}
        <Stat
          title="Fan"
          data={aggregate_statuses(firebaseData, StatusTypes.Fan)}
          type={StatusTypes.Fan}
          unit=""
        />
        <Stat
          title="Lights"
          data={aggregate_statuses(firebaseData, StatusTypes.Lights)}
          type={StatusTypes.Lights}
          unit=""
        />
        <Stat
          title="PDLC"
          data={aggregate_statuses(firebaseData, StatusTypes.PDLC)}
          type={StatusTypes.PDLC}
          unit=""
        />
        {/* <div>
          GPT Recommendations
          <div></div>
        </div> */}
      </div>
    </div>
  );
}

export default App;
