import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import styles from "./Stat.module.scss";

const Stat = ({
  title,
  data,
  type,
  unit,
}: {
  title: string;
  data: any[];
  type: string;
  unit: string;
}) => {
  console.log(data);
  return (
    <div className={styles.statContainer}>
      <div className={styles.heading}>
        <div className={styles.title}>{title}</div>
        <div className={styles.liveIndicatorContainer}>
          <div className={styles.liveIndicator}>
            <div className={styles.dot} />
          </div>
          Live
        </div>
      </div>
      <div className={styles.graphContainer}>
        {(data.length > 0 && (
          <>
            <div className={styles.yaxisUnit}>{unit}</div>
            <LineChart width={500} height={300} data={data}>
              <XAxis dataKey="name" />
              <Tooltip />
              <YAxis />
              <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
              <Line
                isUpdateAnimationActive={false}
                dot={false}
                type="monotone"
                dataKey={type}
                stroke="#8884d8"
              />
            </LineChart>
          </>
        )) || (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            No Data
          </div>
        )}
      </div>
    </div>
  );
};

export default Stat;
